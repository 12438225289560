import React from 'react';
import Title from '../_components/Title';

function Resume() {
  return (
    <>
	<Title name='My Resume'/>
    </>
  )
}

export default Resume