//import React from "react";

//https://jasonwatmore.com/post/2019/04/06/react-jwt-authentication-tutorial-example
//authenticationService

export const logout = () => {
  console.log("auth.logout() activated!");

  // localStorage.removeItem('access_token');
  // localStorage.removeItem('refresh_token');
  // localStorage.removeItem('user');
  localStorage.clear();

  window.location.href = "/";
};