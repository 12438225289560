import React from "react";
//import Navbar from "./_components/Navbar";
import {
  //BrowserRouter as Router,
  Route,
  Routes,
  //Switch,   // has been replaced by Routes
  //Redirect, // has been replaced by Navigate
  Navigate,
} from "react-router-dom";

import Home from "./pages/home";
import User from "./pages/user";
import Clock from "./pages/clock";
import Artist from "./pages/artist";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Dog from "./pages/dog";
import Weather from "./pages/weather";
import Person from "./pages/person";
import Article from "./pages/article";
import Resume from './pages/resume';
import News from './pages/news';
import Stock from './pages/stock';
import StockRealtime from './pages/stockrealtime';
import Chat from './pages/chat';

import { Box } from "@mui/material";

export const MyRoutes = () => {
  return (
    <Box mt={1}>
      {/* <Router> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/user" element={<User />} />
          <Route path="/clock" element={<Clock />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dog" element={<Dog />} />
          <Route path="/weather" element={<Weather />} />
          <Route path="/person" element={<Person />} />
          <Route path="/article" element={<Article />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/news" element={<News />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/stockrealtime" element={<StockRealtime />} />
          <Route path="/chat" element={<Chat />} />


          {/* 
            <Route path="*" element={<Navigate to="/" />}/> 
          */}
          <Route path="*" element={<Navigate to="/" />}/> 
          
        </Routes>
        {/* <Navbar /> */}
      {/* </Router> */}
    </Box>
  );
};

export default MyRoutes;