import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  //Typography,
  Container,
} from "@mui/material";
import { Visibility, VisibilityOff, Login as Lgn } from "@mui/icons-material";
import axiosInstance from "../axiosApi";
import "./login.css";
import Title from '../_components/Title';

// reference
// https://github.com/mui/material-ui/blob/master/docs/data/material/getting-started/templates/sign-in/SignIn.js




function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const recaptchaRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(true);
  };
  const handleMouseUpPassword = () => {
    setShowPassword(false);
  };
  const handleMouseLeavePassword = () => {
    setShowPassword(false);
  };
  const handleTouchStartPassword = () => {
    setShowPassword(true);
  };
  const handleTouchEndPassword = () => {
    setShowPassword(false);
  };

  const handleSubmitWThen = async (event) => {
    event.preventDefault();

    //ref: https://javascript.plainenglish.io/integrate-google-recaptcha-v2-invisible-with-react-and-nodejs-9d119c94433b
    // for robot checkbox captcha
    const recaptchaValue = await recaptchaRef.current.getValue();
    // for invisible captcha
    //const recaptchaValue = await recaptchaRef.current.executeAsync();

    recaptchaRef.current.reset();

    await axiosInstance
      .post(process.env.REACT_APP_API_TOKEN_OBTAIN_URL, {
        username: username,
        password: password,
        recaptchaValue: recaptchaValue,
      })
      .then((result) => {
        localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          result.data.access
        );
        localStorage.setItem(
          process.env.REACT_APP_REFRESH_TOKEN,
          result.data.refresh
        );
        axiosInstance.defaults.headers[
          "Authorization"
        ] = `JWT ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)}`; //"JWT " + response.data.access;

        //return "wow! success!";
      })
      .catch((error) => {
        throw error;
      });
  };

  const onRecaptchaChange = () => {
    setDisableSubmit(false);
  };

  return (
    <React.Fragment>
      <script
        src="https://www.google.com/recaptcha/api.js"
        async
        defer
      ></script>

      <Title name='Login'/>
      <img id="girl" src="https://www.cafedecoral.com/share/img/figures-animated/women-yellow.svg" alt=""/>

      <Container 
        component="main" 
        maxWidth="xs" 
        disableGutters
        >
        <Box
          id="mybox"
          sx={{
            id: "mybox",
            m: 1,
            p: 1,
            //width: 500,
            //height: 500,
            justifyContent: "center",
            //border: 2,
            //borderRadius: "5%",
            //borderColor: 'primary.main'
            //bgcolor: "primary.main",
          }}
        >
          <form onSubmit={handleSubmitWThen}>
            <Grid
              container
              spacing={1}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={8}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  id="username"
                  label="User Name"
                  variant="outlined"
                  autoComplete="username"
                  helperText="Please enter your username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  inputProps={{
                    maxLength: process.env.REACT_APP_ENTRY_MAX_LENGTH,
                    minLength: process.env.REACT_APP_ENTRY_MIN_LENGTH,
                    // style: {
                    //   textAlign: 'center',
                    //   width: '300px',
                    // },
                    // autocomplete: 'username',
                    // form: {
                    //   autocomplete: 'on',
                    // }
                  }}
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  autoComplete="current-password"
                  helperText="Plesae enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{
                    maxLength: process.env.REACT_APP_ENTRY_MAX_LENGTH,
                    minLength: process.env.REACT_APP_ENTRY_MIN_LENGTH,
                    // style: {
                    //   textAlign: 'center',
                    //   width: '300px',
                    // },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseUpPassword}
                          onMouseLeave={handleMouseLeavePassword}
                          onTouchStart={handleTouchStartPassword}
                          onTouchEnd={handleTouchEndPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  onChange={onRecaptchaChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  disabled={disableSubmit}
                  variant="outlined"
                  size="large"
                  endIcon={<Lgn />}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
}
//}
export default Login;
