//import logo from './logo.svg';
import React, { useState, createContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
//import CssBaseline from "@mui/material/CssBaseline";
//import { StyledEngineProvider } from "@mui/material/styles";
import "./App.css";
import Toggle from "./toggle/toggle";
import Pages from "./pages";
import { theme, lightTheme, darkTheme } from "./theme";
import { Paper } from "@mui/material";

// Context API
// https://youtu.be/TNhaISOUy6Q?t=375
// const moods = {
//   happy: "😊",
//   said: "😥",
// };
// const MoodContext = createContext(moods);

// const MoodEmoji = () => {
//   //const mood = useContext(MoodContext);
//   <MoodContext.Consumer>{<p>{moods}</p>}</MoodContext.Consumer>;
// };

function App() {
  const [darkMode, setDarkMode] = useState(false);

  //theme.palette.darkMode ? "dark" : "light";

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ height: "100vh" }}>
        {/* <MoodContext.Provider value={moods.happy}> */}
        <div>
          <Toggle />
          <Pages />
        </div>
        {/* </MoodContext.Provider> */}
      </Paper>
    </ThemeProvider>
  );
}

export default App;
