import React, { useState, useEffect } from "react";
import { io } from "socket.io-client"; // https://socket.io/docs/v4/client-options/
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import dateFormat from "dateformat";
import "chartjs-adapter-moment";

//https://react-chartjs-2.netlify.app/docs/migration-to-v4
//import {Chart as ChartJS }from 'chart.js/auto';
// supports tree-shaking: https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale
// NEED TO REGISTER AGAIN!
//import {Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title as ChartTitle, CategoryScale} from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

import "chartjs-adapter-luxon";
import ChartStreaming from "chartjs-plugin-streaming";
//import { StreamingPlugin, RealTimeScale } from 'chartjs-plugin-streaming';

import { Bar, Line } from "react-chartjs-2";
//import faker from '@faker-js/faker';   // NO TREE-SHAKABLE! DONT USE, USE FALSO

import Title from "../_components/Title";
import Subtitle from "../_components/Subtitle";

import styles from "./stock.module.css";

//tree-shakable way
ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  CategoryScale,
  TimeScale
);

// for streaming
// https://stackoverflow.com/questions/70777779/time-series-line-chart-js-in-react-not-working
// https://stackoverflow.com/questions/68557843/streaming-real-time-data-with-react-and-chartjs-plugin-streaming-paused
// https://codesandbox.io/s/admiring-ardinghelli-wk15h?file=/src/components/HelloWorld.vue:359-373
//ChartJS.register(StreamingPlugin, RealTimeScale);
ChartJS.register(ChartStreaming);

function Stock() {
  const NAMESPACE = "/stockNamespace";
  const SOCKETIO_NAMESPACE =
    process.env.REACT_APP_DEVELOPMENT_MODE === "true"
      ? `http://localhost:5000${NAMESPACE}`
      : `${process.env.REACT_APP_PRODUCTION_URL}${NAMESPACE}`;
  const STOCK_DATA = "stock:data";

  const [stockData, setStockData] = useState({});

  const valueOrDefault = (value, defaultValue) => {
    return typeof value === "undefined" ? defaultValue : value;
  };

  let _seed = Date.now();
  const rand = (min, max) => {
    min = valueOrDefault(min, 0);
    max = valueOrDefault(max, 0);
    _seed = (_seed * 9301 + 49297) % 233280;
    return min + (_seed / 233280) * (max - min);
  };



  // https://codesandbox.io/s/tpnkd?file=/src/App.js:156-1051
  // real time chart
  // https://www.youtube.com/watch?v=fIZiD-c1Tek
  const data = {
    //labels: ['red', 'blue'], //, 'yellow', 'green', 'purple', 'orange'],
    datasets: [
      {
        label: "Dataset 1 (Linear Interpolation)",
        backgroundColor: "red",
        borderColor: "red",
        borderDash: [8, 4],
        fill: true,
        data: [],
        borderWidth: 3,
      },
      {
        label: "Dataset 2 (Cubic Interpolation)",
        backgroundColor: "blue",
        borderColor: "blue",
        cubicInterpolationMode: "monotone",
        fill: true,
        data: [],
        borderWidth: 3,
      },
    ],
  };
  const [chartData, setChartData] = useState(data);

  //setChartData(data);



  //https://nagix.github.io/chartjs-plugin-streaming/latest/samples/charts/line-horizontal.html
  const onRefresh = (chart) => {
    const now = Date.now();
    chart.data.datasets.forEach((dataset) => {
      //let dataset = chartData.data.datasets[0];
      //console.log('my dataset size: '+ dataset.size);
      //console.log('** chart length: ' + dataset.data.length);
      let random_y = rand(-100, 100);
      dataset.data.push({
        x: now,
        y: random_y,
      });
      //console.log(`push data: ${now} / ${random_y}`);
    });
  };

  // https://stackoverflow.com/questions/69011743/chartjs-plugin-streaming-onrefresh-callback-isnt-working
  const options = {
    type: "line",
    data: data,
    responsive: true,
    //maintainAspectRatio: false,
    //rTdata,
    scales: {
      x: {
        type: "realtime",
        stacked: true,
        title: {
          display: true,
          text: "Timeline 時間",
          fontSize: 30,
        },
        realtime: {
          duration: 60000, // 1 min
          refresh: 1000, // 5 sec
          delay: 2000, // https://nagix.github.io/chartjs-plugin-streaming/master/tutorials/plainjs/delay.html
          onRefresh: onRefresh,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Value 價格",
          fontSize: 30,
        },
        grid: {
          color: 'gray',
          lineWidth: 2,
        },
        // ticks: {
        //   max: 100,
        //   min: -100
        // }
        //beginAtZero: true
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Real Time Chart",
        color: 'orange',
        font: {
          size: 40,
        },
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "rgb(0, 128, 0)", //green
          font: {
            size: 18
          }
        },
      },
      // streaming:{
      //   frameRate: 30
      // }
    },
  };

  useEffect(() => {
    //ENDPOINT is the namespace
    //path is not the namespace!
    //read https://socket.io/docs/v4/client-options/
    const socket = io(SOCKETIO_NAMESPACE, {
      transports: ["websocket"],
      //protocols: ["http"],
      path: "/kcysocket",
    });

    // https://stackoverflow.com/questions/69008820/websocket-connection-error-insufficient-resources
    socket.on("connection", () => {});

    socket.on(STOCK_DATA, (stockData) => {
      setStockData(stockData);

      // console.log('^^ datasets.length: ' + chartData.datasets[0].data);
      
      // setChartData(chartData.datasets.data.push({
      //     x: Date.now(),
      //     y: rand(-100, 100)
      // }));

      // setChartData(
      //   chartData.datasets.forEach((dataset) => {
      //     //console.log('dadadadada: ' + dataset.data[0]);
      //     dataset.data.push({
      //       x: Date.now(),
      //       y: rand(-100, 100)
      //     })
      //   })
      // );
      
      

      // setChartData(() => {
      //   return chartData?.datasets[0]?.data.push({
      //     x: Date.now(),
      //     y: rand(-100, 100)
      //   })
      // });
      //console.log('HELLO!');
    });

    return () => {
      //setStocks({}); // https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
      socket.disconnect();

      console.log("STOCK SOCKET IS DISCONNECT!");
    };

    //}, [socket]);
  }, [SOCKETIO_NAMESPACE]);




  //to solve first load empty null stockData data error:
  //TypeError: Cannot read properties of undefined (reading 'map')
  if (!Array.isArray(stockData.stocks)) {
    return <></>;
  }



  return (
    <>
      <Title name="Real-Time Stock Simulator" />
      <Subtitle
        name={`Stock Data will be reset every ${
          stockData.resetInterval
        } minutes, next reset time at ${dateFormat(
          stockData.resetTime,
          "HH:MM:ss"
        )}`}
      />
      
      {/* <TableContainer component={Paper}> */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 100 }}>Symbol</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Name</TableCell>
              <TableCell sx={{ minWidth: 100 }} align="right">
                Price
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="right">
                Delta
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="right">
                Last Price
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="right">
                Volume
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="right">
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stockData.stocks.map((row) => (
              <TableRow
                key={row.symbol}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.symbol}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    parseFloat(row.price) >= parseFloat(row.lastPrice)
                      ? `${styles.text_green}`
                      : `${styles.text_red}`
                  }
                >
                  {row.price.toFixed(2)}
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    parseFloat(row.price) >= parseFloat(row.lastPrice)
                      ? `${styles.text_green}`
                      : `${styles.text_red}`
                  }
                >
                  {(parseFloat(row.price) - parseFloat(row.lastPrice)).toFixed(
                    2
                  )}{" "}
                  (
                  {(
                    ((parseFloat(row.price) - parseFloat(row.lastPrice)) /
                      parseFloat(row.lastPrice)) *
                    100.0
                  ).toFixed(2)}
                  %)
                </TableCell>
                <TableCell align="right">{row.lastPrice}</TableCell>
                <TableCell align="right">{row.volume.toFixed(0)}</TableCell>
                <TableCell align="right">{row.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Line data={chartData} options={options} />
    </>
  );
}

export default Stock;
