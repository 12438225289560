import React, { useState } from "react";
import axios from "axios";

import Container from "@mui/material/Container";

//https://www.pluralsight.com/guides/how-to-implement-infinite-scrolling-with-reactjs
// https://medium.com/suyeonme/react-how-to-implement-an-infinite-scroll-749003e9896a

function ScrollComponent() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [prevY, setPrevY] = useState(0);

  const getPhotos = (page) => {
    setLoading(true);
    axios
      .get(
        `https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=10`
      )
      .then((res) => {
        setPhotos([...photos, ...res.data]);
        setLoading(false);
      });
  };

  return (
    <div>
      <Container></Container>
    </div>
  );
}

export default ScrollComponent;
