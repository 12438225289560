import React from "react";
//import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Title from '../_components/Title';
import { logout } from "../_services";
//import Navbar from "../_components/Navbar";
// import auth from '../_services';

const styles = {
  color: "red",
  background: "#0f0",
  fontSize: "32px",
};

const Home = () => {
  // https://mui.com/components/dialogs/
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (lng) => {
    //i18n.changeLanguage(lng);
    setOpen(true);
  };

  return (
    <div>
      <Title name='Home'/>
      <h1>
        {/* https://medium.com/nerd-for-tech/get-global-variables-in-react-js-490cf68f2a73 */}
        We are using{" "}
        <span style={{ color: styles.color }}>
          {process.env.REACT_APP_DATABASE}
        </span>
      </h1>
      <h3>
        Current development mode is:{" "}
        {process.env.REACT_APP_DEVELOPMENT_MODE === "true" ? "Apple" : "Orange"}
      </h3>

      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <button onClick={() => handleClickOpen("zh-tw")}>中文</button>
        <button onClick={() => handleClickOpen("en")}>English</button>
        <button onClick={() => handleClickOpen("es")}>Español</button>
        
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
};
export default Home;
