import React from 'react';
import ReactDOM from 'react-dom';
import Title from '../_components/Title';

class Clock extends React.Component {
	
	constructor(props) {
	  super(props);
	  this.state = {date: new Date()};
	}
      
	componentDidMount() {
	  this.timerID = setInterval(
	    () => this.tick(),
	    1000
	  );
	}
      
	componentWillUnmount() {
	  clearInterval(this.timerID);
	}
      
	tick() {
	  this.setState({
	    date: new Date()
	  });
	}
      
	render() {
	  return (
	    <div>
	      <Title name='Clock'/>
	      <h2>It is {this.state.date.toLocaleTimeString()}</h2>
	    </div>
	  );
	}
}
      
ReactDOM.render(
<Clock />,
document.getElementById('root')
);

export default Clock;