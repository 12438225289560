import React, { Component } from "react";
import { TextField, Button, Grid, Box, Slider } from "@mui/material";
import Title from '../_components/Title';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    alert(
      "A username and password was submitted: " +
        this.state.username +
        " " +
        this.state.password +
        " " +
        this.state.email
    );
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <Title name='Sign Up'/>

        <hr />
        <Box m={1} p={5}>
        <form onSubmit={this.handleSubmit}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={8}>
            <TextField
            id="username"
            label="User Name"
            variant="outlined"
            name="username"
            value={this.state.username}
            onChange={this.handleChange}
          />
            </Grid>
            <Grid item xs={4}>
            <TextField
            id="email"
            label="Email"
            variant="outlined"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={8}>
              <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
            </Grid>
            <Grid item xs={4}>
              <Button type="submit" variant="outlined">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        </Box>
      </div>
    );
  }
}
export default Signup;
