import React from 'react'
import { useSelector } from 'react-redux';
import ScrollComponent from '../_components/ScrollComponent';
import Title from "../_components/Title";

function News() {
  const counter = useSelector(state => state.counter);
  const isLogged = useSelector(state => state.isLogged);
  return (
    <>
	    <Title name="News"/>
      <h3>count: {counter}</h3>
      <h3>logged: {isLogged ? <h4>You've logged in</h4> : "Please login in"}</h3>
	    <ScrollComponent/>
    </>
  )
}

export default News