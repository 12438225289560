import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import {createStore } from 'redux';
import allReducers from './reducers'; // since there's index.js in the folder, so reference folder is ok
import { Provider } from 'react-redux';


// use StyledEngineProvider's injectFirst to override style of MUI
// old version only with explain: https://stackoverflow.com/questions/53065983/can-i-override-material-ui-with-css-modules-in-create-react-app-v2
// new version v5: https://stackoverflow.com/questions/68593907/stylesprovider-vs-styledengineprovider-in-material-ui-v5
import {
  //ThemeProvider,
  CssBaseline,
  StyledEngineProvider, // use StyledEngineProvider to override style of MUI
} from "@mui/material"; //

//import { createTheme, responsiveFontSizes } from "@mui/material/styles";

//const muiTheme = responsiveFontSizes(createTheme({/* your custom theme */}));
const store = createStore(
  allReducers,

);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>

      <Router>
        <StyledEngineProvider injectFirst>
            <CssBaseline />
            <App />
        </StyledEngineProvider>
      </Router>
      
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
