import React, { useState, useEffect } from "react";
import { io } from "socket.io-client"; // https://socket.io/docs/v4/client-options/
//import Button from "@mui/material/Button";
import dateFormat from "dateformat";

import Title from "../_components/Title";
// ref: https://www.freecodecamp.org/news/how-to-create-a-realtime-app-using-socket-io-react-node-mongodb-a10c4a1ab676/
// https://stackoverflow.com/questions/61646928/websockets-proxy-using-socket-io-client-in-create-react-app
import "./chat.css";


function Chat() {
  const NAMESPACE = "/chatNamespace";
  const SOCKETIO_NAMESPACE = process.env.REACT_APP_DEVELOPMENT_MODE === "true"
  ? `http://localhost:5000${NAMESPACE}`
  : `${process.env.REACT_APP_PRODUCTION_URL}${NAMESPACE}`;
  const CHAT_MESSAGE = "chat:message";
  
  //https://stackoverflow.com/questions/57829653/accessing-my-socket-variable-outside-of-useeffect
  const [currentSocket, setCurrentSocket] = useState(null);
  
  const getDatetime = () => {
    return dateFormat(new Date(), "yyyy/mm/dd hh:MM:ss");
  };

  const bindMessage = (msg) => {
      let messages = document.getElementById("messages");
      let item = document.createElement("li");
      item.textContent = `${msg}`;
      messages.appendChild(item);
      window.scrollTo(0, document.body.scrollHeight);    
  }

  useEffect(() => {
    const socket = io(SOCKETIO_NAMESPACE, {
      transports: ["websocket"],
      protocols: ["http"],
      path: "/kcysocket",
    });

    socket.on("connection", (socket) => {
      console.log(`${getDatetime()} a user is connected with socket.id ${socket.id}`);
      socket.on("add_user", "philip");
    });

    socket.on(CHAT_MESSAGE, (msg) => {
        bindMessage(msg);
    });

    socket.on("server_message", (data) => {
      console.log(`Received message from server: ${data.name}->${data.message}`);
    });

    setCurrentSocket(socket);

    return () => {
      socket.disconnect();
      console.log("CHAT SOCKET IS DISCONNECT!");
    };

  }, [SOCKETIO_NAMESPACE]);


  const handleSubmit = (event) => {
    event.preventDefault();

    var input = document.getElementById("input");

    if (input.value) {
      //console.log("input.value: " + input.value);
      currentSocket.emit(CHAT_MESSAGE, `${currentSocket.id}: ${input.value}`);
      currentSocket.emit("join", 12);
      currentSocket.emit("add_user", "philip");
      input.value = "";
    }
  };


  return (
    <>
      <Title name="Chat" />

      <ul id="messages" />
      <form id="form" onSubmit={handleSubmit}>
        {/* <form id="form"> */}
        <input id="input" autoComplete="off" />
        {/* <button onClick={sendMessage}>Send</button> */}
        <button>Send</button>
        {/* <Button onClick={() => sendMessage() }>Send</Button> */}
      </form>
    </>
  );
}

export default Chat;
