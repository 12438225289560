import React, { useState, useEffect } from "react";
//import axios from "axios";
// import {
//   TextField,
//   Button,
//   Grid,
//   Box,
//   InputAdornment,
//   IconButton,
//   Typography,
//   Container,
// } from "@mui/material";
import axiosInstance from "../axiosApi";

import Title from "../_components/Title";

// to use http-proxy-middleware or not based on the global variable in <root>/.env file
const api_url =
  process.env.REACT_APP_DEVELOPMENT_MODE === "true"
    ? "/core/artists/"
    : "https://secure.majorone.com/api/core/artists/";

const Artist = (props) => {
  const [artists, setArtists] = useState([]);
  const [requestError, setRequestError] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          //console.log("fetching...");
          const result = await axiosInstance.get(api_url);
          setArtists(result.data);
          //console.log("result data: " + result.data);
        } catch (error) {
          setRequestError(error.message);
          //console.log("Error: ", JSON.stringify(requestError, null, 4));
        }
      };

      fetchData();
      //console.log("calling fetchData()...");
    },
    // https://maxrozen.com/learn-useeffect-dependency-array-react-hooks
    // only fetch one if [] dependencies is empty, https://youtu.be/TNhaISOUy6Q
    [requestError, props.id] //<-- This is the dependency array
  );

  if (artists) {
    return (
      <div>
        <Title name="Artist" />

        <ul>{
          artists.map(artist => {
            return <li key={artist.id}>{artist.last_name}</li>
          
          })
        }
        </ul>
      </div>
    );
  } else {
    return <>Nothing to show, may be unauthorized</>;
  }
};

export default Artist;
