// https://www.digitalocean.com/community/tutorials/react-axios-react
import React from "react";
import axios from "axios";

export default class PersonList extends React.Component {
  state = {
    persons: [],
  };

  constructor() {
    super();
    // to use http-proxy-middleware or not based on the global variable in <root>/.env file
    this.api_url =
      process.env.REACT_APP_DEVELOPMENT_MODE === "true"
        ? "/api-typicode/users"
        : "https://jsonplaceholder.typicode.com/users";

    console.log("********* you are using " + this.api_url);
  }

  componentDidMount() {
    //axios.get(`https://jsonplaceholder.typicode.com/users`) // for nginx production
    //axios.get("/users") // for local development, http-proxy-middleware
    axios.get(this.api_url).then((res) => {
      const persons = res.data;
      this.setState({ persons });
    });
  }

  render() {
    return (
      <ul>
        {this.state.persons.map((person) => (
          <li key={person.id}>{person.name}</li>
        ))}
      </ul>
    );
  }
}
