import React from 'react'
import {
  Typography,
} from "@mui/material";

export default function Subtitle(props) {
  return (
    <div>
      <Typography
        variant="h6"
        mt={5}
        color="primary.dark"
        align="center"
        gutterBottom
      >
        {props.name}
      </Typography>
    </div>
  )
}
