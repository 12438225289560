import React, { useState, useEffect } from "react";
import PersonList from "../_components/PersonList";
import PersonAdd from '../_components/PersonAdd';

import './person.css';

const Person = () => {
  return (
    <div className="main">
      <h2>Person</h2>
      <PersonList />
      <PersonAdd />
    </div>
  );
};
export default Person;
