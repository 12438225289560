import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Title from "../_components/Title";
import "./weather.css";

//import { API_WEATHER_URL } from "../setupProxy";
//import { API_WEATHER_URL_PATH } from "../setupProxy";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// to use http-proxy-middleware or not based on the global variable in <root>/.env file
const dataType = "flw"; // flw fnd rhrread
const language = "tc"; // en tc
const api_url =
  process.env.REACT_APP_DEVELOPMENT_MODE === "true"
    ? `/weatherAPI/opendata/weather.php?dataType=${dataType}&lang=${language}`
    : "https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=en";

const Weather = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [weather, setWeather] = useState([]);
  const [weather, setWeather] = useState({});
  useEffect(() => {
    //fetch("https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=en") // for nginx production
    //fetch("/weatherAPI/opendata/weather.php?dataType=flw&lang=en") // for local development, http-proxy-middleware
    fetch(api_url)
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setWeather(data);
          //console.log("normal weather: " + data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          //console.log("error weather: " + error);
        }
      );
  }, []); // only fetch one if [] dependencies is empty
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="content">
        <Title name="Weather" />

        <Box mb={10}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 2, md: 4 }}
            divider={<Divider orientation="vertical" flexItem />}
            alignItems="stretch"
            justifyContent="space-around"
          >
            <Item sx={{ boxShadow: 3 }}>{weather.generalSituation}</Item>
            <Item sx={{ boxShadow: 3 }}>{weather.forecastPeriod}</Item>
            <Item sx={{ boxShadow: 3 }}>{weather.forecastDesc}</Item>
            <Item sx={{ boxShadow: 3 }}>{weather.outlook}</Item>
          </Stack>
        </Box>

        <hr />

        <Typography variant="caption">
          Updated on {new Date(weather.updateTime).toGMTString()}
          {/*
          <li>{weather.tcInfo}</li>
          <li>{weather.fireDangerWarning}</li>
          */}
          
          <a
            href="https://data.weather.gov.hk/weatherAPI/doc/HKO_Open_Data_API_Documentation.pdf"
            target="_blank"
            rel="noreferrer"
          >
            API Guide
          </a>
        </Typography>
      </div>
    );
  }
};
export default Weather;
