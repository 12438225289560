// https://www.digitalocean.com/community/tutorials/react-axios-react

import React from "react";
import axios from "axios";

import "./PersonAdd.css";

export default class PersonAdd extends React.Component {
  state = {
    name: "",
    password: "",
    email: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const user = {
      name: this.state.name,
      password: this.state.password,
      email: this.state.email,
    };

    //const { name, password, email } = this.state;

    //axios.post(`https://jsonplaceholder.typicode.com/users`, { user }) // nginx production
    axios
      .post(`/users`, { user }) // http-proxy-middleware
      .then((result) => {
        console.log(result);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="add-form">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="name"
            onChange={this.handleChange}
          />
          <br />
          <label htmlFor="name">Password</label>
          <input
            type="text"
            id="password"
            name="password"
            placeholder="password"
            onChange={this.handleChange}
          />
          <br />
          <label htmlFor="name">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="email"
            onChange={this.handleChange}
          />
          <br />
          <button type="submit">Add</button>
        </form>
      </div>
    );
  }
}
