import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import {Box, Container} from '@mui/material';

import Title from "../_components/Title";
import Subtitle from "../_components/Subtitle";
import "./dog.css";

const Dog = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [dog, setDog] = useState([]);
  const [dog, setDog] = useState({});

  // to use http-proxy-middleware or not based on the global variable in <root>/.env file
  const api_url =
    process.env.REACT_APP_DEVELOPMENT_MODE === "true"
      ? "/api-dog/breeds/image/random"
      : "https://dog.ceo/api/breeds/image/random";

  useEffect(() => {

    const fetchData = async () => {
        await fetch(api_url)
        .then((res) => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setDog(data);
            //console.log("dog: " + data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
            //console.log("dog error: " + error);
          }
        );
      };      
    
    fetchData(); // no need to wait 5 seconds for the first time
    const interval = setInterval(fetchData, 5000); // https://stackoverflow.com/questions/57542264/how-to-setinterval-for-every-5-second-render-with-react-hook-useeffect-in-react
    return () => clearInterval(interval);
    
  }, [api_url]); // only fetch one if [] dependencies is empty, https://youtu.be/TNhaISOUy6Q
  //}, []);




  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div><CircularProgress /> Loading...</div>;
  } else {
    return (
      <>
        <Title name="Dog Image" />
        <Subtitle name="Refresh every 5 seconds"/>

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            justifyContent: "center",
          }} 
          fullWidth
          >
          <img src={dog.message} alt="" />
        </Box>
        </Container>
      </>
    );
  }
};
export default Dog;
