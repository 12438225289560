import React from 'react'
import {
  Typography,
} from "@mui/material";

export default function Title(props) {
  return (
    <div>
      <Typography
        variant="h3"
        mt={5}
        color="primary"
        align="center"
        gutterBottom
      >
        {props.name}
      </Typography>
    </div>
  )
}
