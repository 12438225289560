import axios from "axios";
//import { Navigate } from "react-router-dom";
import createAuthRefreshInterceptor from "axios-auth-refresh";
//import { useNavigate } from 'react-router-dom';
//import { useEffect } from "react";

// to use http-proxy-middleware or not based on the global variable in <root>/.env file
const api_url =
  process.env.REACT_APP_DEVELOPMENT_MODE === "true"
    ? "/api-django"
    : "https://secure.majorone.com/api";

const TOKEN_REFRESH_URL = process.env.REACT_APP_API_TOKEN_REFRESH_URL; //"/token/refresh/";
const JSON_TYPE = "application/json";

// Obtain the fresh token each time the function is called
const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);
}

const getRefreshToken = () => {
    return localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN);
}

const setToken = (token) => {
    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token);
}

const setRefreshToken = (refreshToken) => {
    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, refreshToken);
}

const goToLoginURL = () => {
    window.location.href = process.env.REACT_APP_LOGIN_URL;
}

const axiosInstance = axios.create({
  baseURL: api_url,
  timeout: 5000,
  headers: {
    Authorization: `JWT ${getToken()}`,
    "Content-Type": JSON_TYPE,
    accept: JSON_TYPE,
  },
});

axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `JWT ${getToken()}` || "";
      return config;
    },
    (error) => {

      if (error.response){
        switch (error.response.status) {
          case 404:
            console.log("你要找的頁面不存在")
            // go to 404 page
            break
          case 500:
            console.log("程式發生問題")
            // go to 500 page
            break
          default:
            console.log("error: " + error.message)
        }
      }



      return Promise.reject(error);
    }
  );

const refreshAuthLogic = async failedRequest => {

  return axiosInstance
    .post(TOKEN_REFRESH_URL, {
      refresh: getRefreshToken(),
    })
    .then((tokenRefreshResponse) => {
      const { access, refresh } = tokenRefreshResponse.data;
      setToken(access);
      setRefreshToken(refresh);
      failedRequest.response.config.headers["Authorization"] = `JWT ${getToken()}`;
      return Promise.resolve();
    })

    .catch((error) => {
      goToLoginURL();
      //  navigate(process.env.REACT_APP_LOGIN_URL, { replace: true }); 
      // useEffect(() => {
      //      let navigate = useNavigate();
      //      return navigate(process.env.REACT_APP_LOGIN_URL, { replace: true }); 
      // },[]);
      //return <Navigate to="/login" />;
      return Promise.reject(error);
    });
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
  statusCodes: [ 401, 403 ], // default: [ 401 ]
  //skipAuthRefresh: true,
});

export default axiosInstance;
