import React, { useState, useEffect } from "react";
const User = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  
  
  // to use http-proxy-middleware or not based on the global variable in <root>/.env file
  const api_url =
  process.env.REACT_APP_DEVELOPMENT_MODE === "true"
  ? "api-typicode/users"
  : "https://jsonplaceholder.typicode.com/users";

  useEffect(() => {
    //fetch("https://jsonplaceholder.typicode.com/users") // for nginx production
    //fetch("/users") // for local development, http-proxy-middleware
    fetch(api_url) // condition based on global variable? dev or production?
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setUsers(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [api_url]); // add api_url to avoid error: "React Hook useEffect has a missing dependency", ref: https://ithelp.ithome.com.tw/articles/10225504
  
  
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <ul>
        {users.map((user) => (
          <li key={user.id}>{user.name}</li>
        ))}
      </ul>
    );
  }
};
export default User;
