import { createTheme } from '@mui/material/styles';

// https://snappywebdesign.net/blog/how-to-code-a-dark-theme-with-material-ui/
const baseTheme = createTheme({
  // typography: {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: 14,
  //   fontFamilySecondary: "'Roboto Condensed', sans-serif"
  // }
  typography: {
    fontSize: 16,
    h3: {
      fontWeight: 700,
      fontSize: '2.2rem'
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem'
    },
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    }
  }
})

const theme = createTheme({
  ...baseTheme,
  palette: {
    //type: toggleDark ? 'dark' : 'light',
    primary: {
      main: '#1976d2'
    },
    success: {
      main: '#4caf50'
    }
  }
})

// https://snappywebdesign.net/blog/how-to-code-a-dark-theme-with-material-ui/
 const darkTheme = createTheme({
    ...baseTheme,
    palette: {
      type: "dark",
      primary: {
        main: "#26a27b"
      },
      secondary: {
        main: "#fafafa"
      }
    }
  })

   const lightTheme = createTheme({
    ...baseTheme,
    palette: {
      type: "light",
      primary: {
        main: "#fafafa"
      },
      secondary: {
        main: "#26a27b"
      }
    }
  })


  export {theme, lightTheme, darkTheme};
